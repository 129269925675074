import { Box, Center, Group, Select, Skeleton, Stack, Text } from '@mantine/core';
import { IconBolt, IconChevronDown, IconMapPin, IconPlug, IconRosette, IconSolarPanel2, IconWindmill } from '@tabler/icons-react';
import { Map, Marker } from 'mapbox-gl';
import { sum } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchGenerationQuery } from 'amp/api/generators';
import GenerationAndEmissionsCharts from 'amp/components/GenerationChart';
import GeneratorAssignments from 'amp/components/GeneratorAssignments';
import { getAsDOMElement } from 'amp/components/MapIcons/large';
import { useStartAndEndParams } from 'amp/hooks';
import { useGenerator, useGeneratorAssignments } from 'amp/store/generators/hooks';
import { useProgramsPage } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { AssetEventResolution, IUtilityGenerationData } from 'shared/types/assetEvents';
import { IGeneratorMetadata, stateValueToName } from 'shared/types/generator';
import { numberToShortString } from 'shared/utils/strings';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const getIconByFuel = (fuel: string | undefined | null) => {
  switch (fuel) {
    case 'wind':
      return IconWindmill;
    case 'solar':
      return IconSolarPanel2;
    default:
      return IconBolt;
  }
}

const resolutionOptions = [
  { label: 'Hourly', value: AssetEventResolution.HOUR },
  { label: 'Daily', value: AssetEventResolution.DAY },
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  // { label: 'Yearly', value: AssetEventResolution.YEAR },
];

export default function GeneratorView() {
  const oci = useAppSelector(getViewingOpCoId);
  const [params, setParams] = useSearchParams();
  const { generatorId = '' } = useParams<{ generatorId: string }>();

  const {
    start: startDate,
    end: endDate,
  } = useStartAndEndParams({genOrLoad: 'generation'});
  const resolution = params.get('r') || '1d';

  const res = useGenerator(generatorId);
  useEffect(() => {
    if (res.data) {
      tracker.track(TrackEventNames.VG, {startDate: startDate.toISOString(), endDate: endDate.toISOString(), resolution, generatorId, generatorName: res.data.name});
    }
  }, [startDate, endDate, resolution, generatorId, res]);
  const { data: assignments } = useGeneratorAssignments(generatorId);
  const programsRes = useProgramsPage({ perPage: 20, page: 1  });
  const generator = res.data;
  const programs = programsRes.data || [];

  const generationRes = useFetchGenerationQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), generatorIds: [generatorId], resolution, customerIds: [generator?.customer_id || oci],
  }, { skip: !generatorId });
  const generation = generationRes.data?.data || [];

  const map = useRef<Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'generator-page--map-container',
        attributionControl: false,
        interactive: false,
        // center: [-83, 33], // [lng, lat] is expected
        zoom: 4.3,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });
      setMapLoaded(true);
    }
  }, [setMapLoaded]);

  useEffect(() => {
    const mapVal = map.current;

    if (mapLoaded && generator && mapVal) {
      const latLng = (generator.location.lat_lng) as number[]
      if (latLng) {
        const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
        const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
        new Marker(getAsDOMElement()).setLngLat([lng, lat]).addTo(mapVal);
        mapVal.setCenter([lng, lat]);
      }
    }
  }, [generator, mapLoaded]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams
    })
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const Icon = getIconByFuel((generator?.meta as IGeneratorMetadata)?.fuel_category);
  const asGenerationData = generation.map(ae => ae.data as IUtilityGenerationData);
  const totalGenerationMWh = sum(asGenerationData.map(aeData => aeData.sum_generated_wh)) / 1_000_000;
  return (
    <>
      <Group pl="60px" pr="60px">
        <Select
          rightSection={<IconChevronDown size={20} />}
          data={resolutionOptions}
          onChange={onResolutionChange}
          value={resolution}
          className="audit-logs--dropdown"
          w="180px"
        />
        <UtcDatePicker
          isStartDate={true}
          value={startDate}
          maxDate={endDate || undefined}
          minDate={new Date(2021, 0, 1)}
        />
        <div>-</div>
        <UtcDatePicker
          value={endDate}
          minDate={startDate || new Date(2021, 0, 1)}
        />
      </Group>
      <div className="generator-page--scroll-container">
        <Stack gap={16}>
          <Group gap={16}>
            <BasePaper className="generator-page--overview-container">
              <Group>
                <Box h="144px" w="280px">
                  <div id="generator-page--map-container" />
                </Box>
                <Stack gap={8}>
                  <Skeleton visible={res.isLoading}>
                    <Group>
                      <Icon color="var(--color-green-2)" size={28} />
                      <Text fz={20} c="var(--color-blue-2)">{generator?.name || ''}</Text>
                    </Group>
                  </Skeleton>
                  <Skeleton visible={res.isLoading}>
                    <Text fz={12} c="var(--color-blue-2)">ID: {generator?.id || ''}</Text>
                  </Skeleton>
                  <Skeleton visible={res.isLoading}>
                    <Group>
                      <IconMapPin size={16} color="var(--color-blue-2)" />
                      <Text fz={12}>{stateValueToName[(generator?.location.us_state) || ''] || 'Unknown'}</Text>
                    </Group>
                  </Skeleton>
                  <Skeleton visible={res.isLoading}>
                    <Group>
                      {/* TODO: this page only ever shows an owned generator, how will this become dynamic? */}
                      <IconPlug size={16} color="var(--color-blue-2)" />
                      <Text fz={12}>Generation Source: Owned</Text>
                    </Group>
                  </Skeleton>
                </Stack>
              </Group>
            </BasePaper>
            <BasePaper className="generator-page--certs-container">
              <Stack align="center" gap={8}>
                <Text fz={16} fw={600} c="var(--color-blue-2)">Total Certificates</Text>
                <IconRosette size={36} color="var(--color-blue-2)" />
                <Skeleton visible={generationRes.isLoading}>
                  <Center>
                    <Text fz={40} fw={700}>{numberToShortString(totalGenerationMWh)}</Text>
                  </Center>
                </Skeleton>
              </Stack>
            </BasePaper>
          </Group>

          <div className="generator-page--generation-chart-container">
            <GenerationAndEmissionsCharts generatorId={generatorId} startDate={startDate} endDate={endDate} resolution={resolution} />
          </div>

          {generator && <BasePaper>
            <GeneratorAssignments generator={generator} programs={programs || []} assignments={assignments || []} />
          </BasePaper>}
        </Stack>
      </div>
    </>
  );
}
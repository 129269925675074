import { Box, Group, LoadingOverlay, Pill, Select, Stack } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { IconActivity, IconCalendarEvent, IconChartPie, IconChevronDown, IconKeyframeAlignHorizontal } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import CustomerSubscriptions from 'amp/components/SubscriptionsTable';
import { useProgram } from 'amp/store/programs/hooks';
import { getViewingTimeZone } from 'amp/store/ui/selectors';
import Highcharts from 'highcharts';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { ProgramCommitmentType } from 'shared/types/program';
import { daysInMonth, daysInYear, daysOfOverlap, getThisYearEnd, getThisYearStart, isDateWithinDateRange, numberOfDaysBetween } from 'shared/utils/dates';
import { numberToSiFormat, snakeToTitle } from 'shared/utils/strings';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const resolutionOptions = [
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  { label: 'Yearly', value: AssetEventResolution.YEAR },
];

const SubscriptionPipelineChart = ({ programId }: { programId: string }) => {
  const [params, setParams] = useSearchParams();
  const tzName = useAppSelector(getViewingTimeZone);
  const defaultStart = getThisYearStart(tzName);
  defaultStart.setFullYear(defaultStart.getFullYear() - 1);
  const defaultEnd = getThisYearEnd(tzName);
  defaultEnd.setFullYear(defaultEnd.getFullYear() + 5);
  const startDateStr = params.get('s') || defaultStart.toISOString();
  const endDateStr = params.get('e') || defaultEnd.toISOString();
  const resolution = params.get('r') || '1Y';
  // TODO: why does this chart keep re-rendering?
  const programRes = useProgram(programId);
  const { program } = programRes.data;
  const { subscriptions } = useMemo(() => programRes.data, [programRes]);
  const isFixedCommitmentProgram = program?.isFixedCommitment;

  const customersRes = useUtilityCustomers();

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onDateChange = (newDate: Date | null, isStart: boolean) => {
    if (newDate !== null) {
      if (isStart) {
        onParamsChange([{ name: 's', value: newDate.toISOString() }]);
      } else {
        onParamsChange([{ name: 'e', value: newDate.toISOString() }]);
      }
    }
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const getResolutionSeriesValue = (
    subStart: Date,
    subEnd: Date,
    periodStart: Date,
    periodEnd: Date,
    generationPerDayWh: number,
    dedicatedGenTenThousandths: number,
    daysInPeriod: number
  ) => {
    // Check if fixed commitment program
    if (isFixedCommitmentProgram) {
      const generationForPeriodWh = daysOfOverlap(subStart, periodStart, subEnd, periodEnd, false) * generationPerDayWh;
      return generationForPeriodWh / 1_000_000;
    }
    else {
      let percentForPeriodTenThousandths;
      if (!isDateWithinDateRange(subStart, periodStart, periodEnd) && !isDateWithinDateRange(subEnd, periodStart, periodEnd)) {
        // subscription lasts for the entire period
        percentForPeriodTenThousandths = dedicatedGenTenThousandths;
      } else {
        percentForPeriodTenThousandths = (daysOfOverlap(subStart, periodStart, subEnd, periodEnd, false) / daysInPeriod) * dedicatedGenTenThousandths;
      }
      return percentForPeriodTenThousandths / 10_000;
    }
  }

  const getResolutionSeriesValueCallback = useCallback(getResolutionSeriesValue, [isFixedCommitmentProgram])
  // TODO: this function is huge... but the logic is nuanced so I'm leaving it for now
  const pipelineData = useMemo(() => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const subscriptionPipelineData: Record<string, Record<number, number>> = {};
    subscriptions.forEach(sub => {
      const subStart = sub.subscriptionStart;
      const subEnd = sub.subscriptionEnd;
      if (subStart === null || subEnd === null) {
        return false; // TODO: need real logic for how to handle null subscription dates
      }
      const dedicatedGenTenThousandths = sub.percentGenerationDedicatedTenThousandths;
      const committedGenerationWh = sub.committedGenerationWh
      const generationPerDayWh = committedGenerationWh / numberOfDaysBetween(subStart, subEnd, false)
      if (!subscriptionPipelineData[sub.customer_id]) {
        subscriptionPipelineData[sub.customer_id] = {};
      }

      // Start and end of graph display window
      const graphStartYear = startDate.getFullYear();
      const graphEndYear = endDate.getFullYear();

      for (let year = graphStartYear; year <= graphEndYear; year++) {
        const yearStart = new Date(year, 0);
        const yearEnd = new Date(year, 11, 31, 23, 59, 59, 999);
        // bail out of years after subscription ends
        if (yearStart >= subEnd) {
          return;
        }

        // don't process years for subscriptions in the future
        if (yearEnd < subStart) {
          continue;
        }

        if (resolution === '1M') {
          for (let month = 0; month < 12; month++) {
            const monthStart = new Date(year, month, 1);
            const monthEnd = new Date(year, month + 1, 0); // see: https://stackoverflow.com/questions/222309/calculate-last-day-of-month

            // bail out of months after subscription ends or date range ends
            if (monthStart >= subEnd || monthStart > endDate) {
              return;
            }

            // don't process months for subscriptions in the future
            if (monthEnd < subStart) {
              continue;
            }

            if (!subscriptionPipelineData[sub.customer_id][monthStart.getTime()]) {
              subscriptionPipelineData[sub.customer_id][monthStart.getTime()] = 0;
            }
            subscriptionPipelineData[sub.customer_id][monthStart.getTime()] += getResolutionSeriesValueCallback(
              subStart,
              subEnd,
              monthStart,
              monthEnd,
              generationPerDayWh,
              dedicatedGenTenThousandths,
              daysInMonth(year, month + 1),
            )
          }
        } else {
          if (!subscriptionPipelineData[sub.customer_id][yearStart.getTime()]) {
            subscriptionPipelineData[sub.customer_id][yearStart.getTime()] = 0;
          }
          subscriptionPipelineData[sub.customer_id][yearStart.getTime()] += getResolutionSeriesValueCallback(
            subStart,
            subEnd,
            yearStart,
            yearEnd,
            generationPerDayWh,
            dedicatedGenTenThousandths,
            daysInYear(year),
          )
        }
      }
    });
    return subscriptionPipelineData;
  }, [subscriptions, startDateStr, endDateStr, resolution, getResolutionSeriesValueCallback]);


  const customerNamesById = useMemo(() => {
    const namesById: Record<string, string> = {};
    customersRes.data.forEach(c => namesById[c.id] = c.name);

    return namesById;
  }, [customersRes.data]);


  // TODO: why does this constantly render??
  const subscriptionPipelineOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 180,
      events: {
        load() {
          if (!isFixedCommitmentProgram) {
            const max = Math.max(...this.series.map(s => s.dataMax || 0));
            this.yAxis[0].update({ max: Math.max(100, max) });
          }
        }
      }
    },
    lang: {
      noData: 'No subscriptions found, create a subscription to begin',
    },
    // TODO: need like 4 more colors here to be safe
    colors: ['#b1ecdf', '#a6cbea', '#9ba9e7', '#4d7396'],
    yAxis: {
      allowDecimals: false,
      tickInterval: isFixedCommitmentProgram ? undefined : 25,
      labels: {
        formatter: function() {
          if (isFixedCommitmentProgram) {
            const siFormat = numberToSiFormat(Number(this.value) * 1_000_000)
          return `${Number(siFormat.value).toFixed(0)}${siFormat.unitPrefix}Wh`
          }
          return `${this.value}%`
        }
      },
    },
    xAxis: {
      dateTimeLabelFormats: {
        millisecond: '%b \'%y',
        second: '%b \'%y',
        minute: '%b \'%y',
        hour: '%b \'%y',
        day: '%b \'%y',
      }
    },
    legend: {
      enabled: true,
      padding: 0,
      maxHeight: 20,
      itemStyle: {
        fontSize: '10px',
      }
    },
    tooltip: {
      valueSuffix: isFixedCommitmentProgram ? 'MWh': '%',
      valueDecimals: isFixedCommitmentProgram ? 2 : 1,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        minPointLength: 4,
        borderWidth: 0.5,
        pointPadding: 0.3,
        groupPadding: 0,
      },
    },
    series: Object.entries(pipelineData).map(([customerId, percentsByYear]) => {
      return {
        type: 'column',
        name: customerNamesById[customerId] || 'Unknown Customer',
        data: Object.entries(percentsByYear).map(([timestamp, percent]) => {
          const asDate = new Date(parseInt(timestamp));
          return [asDate.getTime(), percent];
        }),
      }
    }),
  }

  return (
    <BasePaper
      className="program-page--subscription-pipeline-container"
      titleContent={<div>Subscription Pipeline</div>}
      actions={
        <Group justify="space-around">
          <Select
            size="xs"
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="120px"
          />
          <MonthPickerInput
            size="xs"
            value={new Date(startDateStr)}
            onChange={d => onDateChange(d, true)}
            rightSection={<IconCalendarEvent size={20} />}
            rightSectionPointerEvents="none"
            numberOfColumns={2}
            valueFormat={'MM/YYYY'}
          />
          <MonthPickerInput
            size="xs"
            value={new Date(endDateStr)}
            onChange={d => onDateChange(d, false)}
            rightSection={<IconCalendarEvent size={20} />}
            rightSectionPointerEvents="none"
            numberOfColumns={2}
            valueFormat={'MM/YYYY'}
          />
        </Group>
      }
    >
      <BaseChart overrideOptions={subscriptionPipelineOptions} />
    </BasePaper>
  );
}

export default function ProgramView() {
  const { programId = '' } = useParams<{ programId: string }>();
  const progRes = useProgram(programId);
  const { program } = progRes.data;
  useEffect(() => {
    if (program) {
      tracker.track(TrackEventNames.VPP, {programId, programName: program.name})
    }
  }, [programId, program]);
  const subscriptions = progRes.data?.subscriptions || [];

  if (progRes.isLoading || !program) {
    return <div className="program-page--scroll-container">
      <Box pos="relative" w="100%" h="100%">
        <LoadingOverlay visible={true} />
      </Box>
    </div>
  }

  return (
    <div className="program-page--scroll-container">
      <Stack gap="16px">
        <Group gap="16px">
          <BasePaper className="program-page--program-details-container" titleContent={
              <>Program Details
                {program.is_hypothetical === true &&
                  <Pill bg='var(--color-grey-0)' c="var(--color-blue-1)" radius="sm" fw="400">
                    Hypothetical
                  </Pill>
                }
                </>
              }>
            <div className="program-page--info-container">
              <Group>
                <IconActivity size="16px" color="var(--color-blue-2)" />
                <div className="program-page--dark-info-text">
                  Program Status: {snakeToTitle(program.status)}
                </div>
              </Group>
              <Group>
                <IconKeyframeAlignHorizontal size="16px" color="var(--color-blue-2)" />
                <div className="program-page--dark-info-text">
                  Temporal Matching Interval: {program.data?.program_config?.accounting_period === 'hourly' ? '1 hour' : 'Annual'}
                </div>
              </Group>
              <Group>
                <IconChartPie size="16px" color="var(--color-blue-2)"/>
                <div className="program-page--dark-info-text">
                  Commitment type: {program.data?.program_config?.commitment_type === ProgramCommitmentType.FIXED_COMMITMENT ? 'Fixed Commitment' : 'Percent of Generation'}
                </div>
              </Group>
            </div>
          </BasePaper>
          <SubscriptionPipelineChart programId={programId} />
        </Group>
        <CustomerSubscriptions program={program} subscriptions={subscriptions} titleRow='customer' />
      </Stack>
    </div>
  );
}